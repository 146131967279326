import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Input, Table, Modal } from 'antd';
import { useSharedValues } from './SharedValueProvider';

const { TextArea } = Input;

const SearchForm = ({ queryList, clickAdd }) => {
    const [formSearch] = Form.useForm();
    return <Form
        form={formSearch}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        onFinish={queryList}
    >
        <Row>
            <Col xs={24} xl={20} >
            </Col>
            <Col xs={18} xl={2} style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={() => {
                    formSearch.submit()
                }}>
                    刷新
                </Button>
            </Col>
            <Col xs={6} xl={2} style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={clickAdd} >
                    添加域名
                </Button>
            </Col>
        </Row>
        <Row>
            <Col >
                <br></br>
            </Col>
        </Row>
    </Form>
};

export default function Certs() {

    const [ds, setDs] = useState([])
    const [ld, setLd] = useState(false)
    const [open, setOpen] = useState(false);
    const { makeRequest } = useSharedValues()
    const [deviceForm] = Form.useForm();

    const queryList = useCallback((values) => {
        setLd(true);
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/certs`, null, setDs, setLd)
    }, [makeRequest])

    useEffect(() => {
        document.title = `Certs`;
        queryList({})
    }, [queryList]);

    const clickAdd = () => {
        deviceForm.resetFields();
        setOpen(true);
    }

    const okfn = () => {
        setOpen(false);
        queryList({});
    }
    const createDev = (values) => {
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/certs`, values, okfn, setLd)
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 48,
        },
        {
            title: '域名',
            dataIndex: 'domain',
            width: 124,
        },
        {
            title: '用途',
            dataIndex: 'use',
            width: 136,
        },
        {
            title: '过期时间',
            width: 128,
            render: (_, r) => {
                let d = r.remain_seconds/(3600*24);
                return r.expire_time+"， 剩余"+ d.toFixed(1)+"天"
            }
        },
        {
            title: '检查时间',
            dataIndex: 'check_time',
            width: 128,
        },
    ]

    return (
        <>
            <SearchForm queryList={queryList} clickAdd={clickAdd} />

            <Modal
                // title="设备信息"
                open={open}
                okText="保存"
                cancelText="取消"
                onCancel={() => {
                    setOpen(false);
                }}
                onOk={() => {
                    deviceForm.submit()
                }}
            >
                <Form
                    form={deviceForm}
                    layout="vertical"
                    onFinish={(values) => {
                        createDev(values)
                    }}
                >
                    <Form.Item
                        label="域名"
                        name="domain"
                        rules={[
                            {
                                required: true,
                                message: '请输入域名！',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="用途"
                        name="use"
                    >
                        <TextArea rows={5} />
                    </Form.Item>
                </Form>
            </Modal>

            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '56vh' }}
                pagination={false}
                bordered={true}
            />
        </>

    );
}
