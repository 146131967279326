import * as React from 'react';

import { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Space, DatePicker, Select, Table, Checkbox } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import moment from 'moment';
import { useSharedValues } from './SharedValueProvider';

const { RangePicker } = DatePicker;

const { Option } = Select;

//分组字段对应的field
let group_fields = {
  store_id: {
    title: "应用",
    dataIndex: "store_id",
    fixed: true,
    width: 250,
  },
  network: {
    title: "渠道",
    dataIndex: "network",
    fixed: true,
    width: 150,
  },
  network_campaign: {
    title: "广告系列",
    dataIndex: "network_campaign",
    fixed: true,
    width: 250,
  },
  country: {
    title: "国家",
    dataIndex: "country",
    fixed: true,
    width: 90,
  },
  os: {
    title: "os",
    dataIndex: "os",
    fixed: true,
    width: 90,
  }
}


let data_fields = [
  {
    title: "展示",
    dataIndex: "impressions",
    width: 90,
  },
  {
    title: "点击",
    dataIndex: "clicks",
    width: 90,
  },
  {
    title: "CTR",
    render: (_, r) => {
      let ctr = '0.00'
      if (r['impressions'] !== '0') {
        ctr = (r['clicks'] / r['impressions']).toFixed(2);
      }
      return ctr
    },
    width: 90,
  },
  {
    title: "非自然",
    dataIndex: "org0",
    width: 90,
  },
  {
    title: "自然",
    dataIndex: "org1",
    width: 90,
  },
  {
    title: "总安装",
    dataIndex: "installs",
    width: 90,
  },
  {
    title: "CVR",
    render: (_, r) => {
      let cvr = '0.00'
      if (r['impressions'] !== '0') {
        cvr = (r['installs'] / r['clicks']).toFixed(2);
      }
      return cvr
    },
    width: 90,
  },
  {
    title: "花费",
    dataIndex: 'spend',
    width: 90,
  },
  {
    title: "CPC",
    render: (_, r) => {
      let cpc = '0.00'
      if (r['clicks'] !== '0') {
        cpc = (r['spend'] / r['clicks']).toFixed(2);
      }
      return cpc
    },
    width: 90,
  },
  {
    title: "CPI",
    render: (_, r) => {
      let cpi = '0.00'
      if (r['installs'] !== '0') {
        cpi = (r['spend'] / r['installs']).toFixed(2);
      }
      return cpi
    },
    width: 90,
  },
  {
    title: "日活",
    dataIndex: "dau",
    width: 90,
  },
  {
    title: "展示2",
    dataIndex: "imp_all",
    width: 90,
  },
  {
    title: "收入",
    dataIndex: 'revenue_usd',
    width: 90,
  },
  {
    title: "ECPM",
    render: (_, r) => {
      let ecpm = '0.00'
      if (r['imp_all'] > 0) {
        ecpm = (r['revenue_usd'] / r['imp_all'] * 1000).toFixed(2)
      }
      return ecpm
    },
    width: 90,
  },
  {
    title: "利润",
    render: (_, r) => {
      return (r['revenue_usd'] - r['spend']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt1',
    render: (_, r) => {
      return (r['rt1'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt2',
    render: (_, r) => {
      return (r['rt2'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt3',
    render: (_, r) => {
      return (r['rt3'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt4',
    render: (_, r) => {
      return (r['rt4'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt5',
    render: (_, r) => {
      return (r['rt5'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt6',
    render: (_, r) => {
      return (r['rt6'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt7',
    render: (_, r) => {
      return (r['rt7'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt8',
    render: (_, r) => {
      return (r['rt8'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt9',
    render: (_, r) => {
      return (r['rt9'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt10',
    render: (_, r) => {
      return (r['rt10'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt11',
    render: (_, r) => {
      return (r['rt11'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt12',
    render: (_, r) => {
      return (r['rt12'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt13',
    render: (_, r) => {
      return (r['rt13'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt14',
    render: (_, r) => {
      return (r['rt14'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt15',
    render: (_, r) => {
      return (r['rt15'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt16',
    render: (_, r) => {
      return (r['rt16'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt17',
    render: (_, r) => {
      return (r['rt17'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt18',
    render: (_, r) => {
      return (r['rt18'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt19',
    render: (_, r) => {
      return (r['rt19'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt20',
    render: (_, r) => {
      return (r['rt20'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt21',
    render: (_, r) => {
      return (r['rt21'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt22',
    render: (_, r) => {
      return (r['rt22'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt23',
    render: (_, r) => {
      return (r['rt23'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt24',
    render: (_, r) => {
      return (r['rt24'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt25',
    render: (_, r) => {
      return (r['rt25'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt26',
    render: (_, r) => {
      return (r['rt26'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt27',
    render: (_, r) => {
      return (r['rt27'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt28',
    render: (_, r) => {
      return (r['rt28'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt29',
    render: (_, r) => {
      return (r['rt29'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt30',
    render: (_, r) => {
      return (r['rt30'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt60',
    render: (_, r) => {
      return (r['rt60'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt90',
    render: (_, r) => {
      return (r['rt90'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'iltv0',
    dataIndex: 'imp_ltv0',
    width: 90,
  },
  {
    title: 'iltv1',
    dataIndex: 'imp_ltv1',
    width: 90,
  },
  {
    title: 'iltv2',
    dataIndex: 'imp_ltv2',
    width: 90,
  },
  {
    title: 'iltv3',
    dataIndex: 'imp_ltv3',
    width: 90,
  },
  {
    title: 'iltv4',
    dataIndex: 'imp_ltv4',
    width: 90,
  },
  {
    title: 'iltv5',
    dataIndex: 'imp_ltv5',
    width: 90,
  },
  {
    title: 'iltv6',
    dataIndex: 'imp_ltv6',
    width: 90,
  },
  {
    title: 'iltv7',
    dataIndex: 'imp_ltv7',
    width: 90,
  },
  {
    title: 'iltv8',
    dataIndex: 'imp_ltv8',
    width: 90,
  },
  {
    title: 'iltv9',
    dataIndex: 'imp_ltv9',
    width: 90,
  },
  {
    title: 'iltv10',
    dataIndex: 'imp_ltv10',
    width: 90,
  },
  {
    title: 'iltv11',
    dataIndex: 'imp_ltv11',
    width: 90,
  },
  {
    title: 'iltv12',
    dataIndex: 'imp_ltv12',
    width: 90,
  },
  {
    title: 'iltv13',
    dataIndex: 'imp_ltv13',
    width: 90,
  },
  {
    title: 'iltv14',
    dataIndex: 'imp_ltv14',
    width: 90,
  },
  {
    title: 'iltv15',
    dataIndex: 'imp_ltv15',
    width: 90,
  },
  {
    title: 'iltv16',
    dataIndex: 'imp_ltv16',
    width: 90,
  },
  {
    title: 'iltv17',
    dataIndex: 'imp_ltv17',
    width: 90,
  },
  {
    title: 'iltv18',
    dataIndex: 'imp_ltv18',
    width: 90,
  },
  {
    title: 'iltv19',
    dataIndex: 'imp_ltv19',
    width: 90,
  },
  {
    title: 'iltv20',
    dataIndex: 'imp_ltv20',
    width: 90,
  },
  {
    title: 'iltv21',
    dataIndex: 'imp_ltv21',
    width: 90,
  },
  {
    title: 'iltv22',
    dataIndex: 'imp_ltv22',
    width: 90,
  },
  {
    title: 'iltv23',
    dataIndex: 'imp_ltv23',
    width: 90,
  },
  {
    title: 'iltv24',
    dataIndex: 'imp_ltv24',
    width: 90,
  },
  {
    title: 'iltv25',
    dataIndex: 'imp_ltv25',
    width: 90,
  },
  {
    title: 'iltv26',
    dataIndex: 'imp_ltv26',
    width: 90,
  },
  {
    title: 'iltv27',
    dataIndex: 'imp_ltv27',
    width: 90,
  },
  {
    title: 'iltv28',
    dataIndex: 'imp_ltv28',
    width: 90,
  },
  {
    title: 'iltv29',
    dataIndex: 'imp_ltv29',
    width: 90,
  },
  {
    title: 'iltv30',
    dataIndex: 'imp_ltv30',
    width: 90,
  },
  {
    title: 'iltv60',
    dataIndex: 'imp_ltv60',
    width: 90,
  },
  {
    title: 'iltv90',
    dataIndex: 'imp_ltv90',
    width: 90,
  },
  {
    title: 'ltv0',
    dataIndex: 'ltv0',
    width: 90,
  },
  {
    title: 'ltv1',
    dataIndex: 'ltv1',
    width: 90,
  },
  {
    title: 'ltv2',
    dataIndex: 'ltv2',
    width: 90,
  },
  {
    title: 'ltv3',
    dataIndex: 'ltv3',
    width: 90,
  },
  {
    title: 'ltv4',
    dataIndex: 'ltv4',
    width: 90,
  },
  {
    title: 'ltv5',
    dataIndex: 'ltv5',
    width: 90,
  },
  {
    title: 'ltv6',
    dataIndex: 'ltv6',
    width: 90,
  },
  {
    title: 'ltv7',
    dataIndex: 'ltv7',
    width: 90,
  },
  {
    title: 'ltv8',
    dataIndex: 'ltv8',
    width: 90,
  },
  {
    title: 'ltv9',
    dataIndex: 'ltv9',
    width: 90,
  },
  {
    title: 'ltv10',
    dataIndex: 'ltv10',
    width: 90,
  },
  {
    title: 'ltv11',
    dataIndex: 'ltv11',
    width: 90,
  },
  {
    title: 'ltv12',
    dataIndex: 'ltv12',
    width: 90,
  },
  {
    title: 'ltv13',
    dataIndex: 'ltv13',
    width: 90,
  },
  {
    title: 'ltv14',
    dataIndex: 'ltv14',
    width: 90,
  },
  {
    title: 'ltv15',
    dataIndex: 'ltv15',
    width: 90,
  },
  {
    title: 'ltv16',
    dataIndex: 'ltv16',
    width: 90,
  },
  {
    title: 'ltv17',
    dataIndex: 'ltv17',
    width: 90,
  },
  {
    title: 'ltv18',
    dataIndex: 'ltv18',
    width: 90,
  },
  {
    title: 'ltv19',
    dataIndex: 'ltv19',
    width: 90,
  },
  {
    title: 'ltv20',
    dataIndex: 'ltv20',
    width: 90,
  },
  {
    title: 'ltv21',
    dataIndex: 'ltv21',
    width: 90,
  },
  {
    title: 'ltv22',
    dataIndex: 'ltv22',
    width: 90,
  },
  {
    title: 'ltv23',
    dataIndex: 'ltv23',
    width: 90,
  },
  {
    title: 'ltv24',
    dataIndex: 'ltv24',
    width: 90,
  },
  {
    title: 'ltv25',
    dataIndex: 'ltv25',
    width: 90,
  },
  {
    title: 'ltv26',
    dataIndex: 'ltv26',
    width: 90,
  },
  {
    title: 'ltv27',
    dataIndex: 'ltv27',
    width: 90,
  },
  {
    title: 'ltv28',
    dataIndex: 'ltv28',
    width: 90,
  },
  {
    title: 'ltv29',
    dataIndex: 'ltv29',
    width: 90,
  },
  {
    title: 'ltv30',
    dataIndex: 'ltv30',
    width: 90,
  },
  {
    title: 'ltv60',
    dataIndex: 'ltv60',
    width: 90,
  },
  {
    title: 'ltv90',
    dataIndex: 'ltv90',
    width: 90,
  }
]

let data_fields_main = [
  {
    title: "展示",
    dataIndex: "impressions",
    width: 90,
  },
  {
    title: "点击",
    dataIndex: "clicks",
    width: 90,
  },
  {
    title: "CTR",
    render: (_, r) => {
      let ctr = '0.00'
      if (r['impressions'] !== '0') {
        ctr = (r['clicks'] / r['impressions']).toFixed(2);
      }
      return ctr
    },
    width: 90,
  },
  {
    title: "非自然",
    dataIndex: "org0",
    width: 90,
  },
  {
    title: "自然",
    dataIndex: "org1",
    width: 90,
  },
  {
    title: "总安装",
    dataIndex: "installs",
    width: 90,
  },
  {
    title: "CVR",
    render: (_, r) => {
      let cvr = '0.00'
      if (r['impressions'] !== '0') {
        cvr = (r['installs'] / r['clicks']).toFixed(2);
      }
      return cvr
    },
    width: 90,
  },
  {
    title: "花费",
    dataIndex: 'spend',
    width: 90,
  },
  {
    title: "CPC",
    render: (_, r) => {
      let cpc = '0.00'
      if (r['clicks'] !== '0') {
        cpc = (r['spend'] / r['clicks']).toFixed(2);
      }
      return cpc
    },
    width: 90,
  },
  {
    title: "CPI",
    render: (_, r) => {
      let cpi = '0.00'
      if (r['installs'] !== '0') {
        cpi = (r['spend'] / r['installs']).toFixed(2);
      }
      return cpi
    },
    width: 90,
  },
  {
    title: "日活",
    dataIndex: "dau",
    width: 90,
  },
  {
    title: "展示2",
    dataIndex: "imp_all",
    width: 90,
  },
  {
    title: "收入",
    dataIndex: 'revenue_usd',
    width: 90,
  },
  {
    title: "ECPM",
    render: (_, r) => {
      let ecpm = '0.00'
      if (r['imp_all'] > 0) {
        ecpm = (r['revenue_usd'] / r['imp_all'] * 1000).toFixed(2)
      }
      return ecpm
    },
    width: 90,
  },
  {
    title: "利润",
    render: (_, r) => {
      return (r['revenue_usd'] - r['spend']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt1',
    render: (_, r) => {
      return (r['rt1'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt3',
    render: (_, r) => {
      return (r['rt3'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt7',
    render: (_, r) => {
      return (r['rt7'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt14',
    render: (_, r) => {
      return (r['rt14'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt30',
    render: (_, r) => {
      return (r['rt30'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt60',
    render: (_, r) => {
      return (r['rt60'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'rt90',
    render: (_, r) => {
      return (r['rt90'] / r['installs']).toFixed(2)
    },
    width: 90,
  },
  {
    title: 'iltv0',
    dataIndex: 'imp_ltv0',
    width: 90,
  },
  {
    title: 'iltv3',
    dataIndex: 'imp_ltv3',
    width: 90,
  },
  {
    title: 'iltv7',
    dataIndex: 'imp_ltv7',
    width: 90,
  },
  {
    title: 'iltv14',
    dataIndex: 'imp_ltv14',
    width: 90,
  },
  {
    title: 'iltv30',
    dataIndex: 'imp_ltv30',
    width: 90,
  },
  {
    title: 'iltv60',
    dataIndex: 'imp_ltv60',
    width: 90,
  },
  {
    title: 'iltv90',
    dataIndex: 'imp_ltv90',
    width: 90,
  },
  {
    title: 'ltv1',
    dataIndex: 'ltv1',
    width: 90,
  },
  {
    title: 'ltv3',
    dataIndex: 'ltv3',
    width: 90,
  },
  {
    title: 'ltv7',
    dataIndex: 'ltv7',
    width: 90,
  },
  {
    title: 'ltv14',
    dataIndex: 'ltv14',
    width: 90,
  },
  {
    title: 'ltv30',
    dataIndex: 'ltv30',
    width: 90,
  },
  {
    title: 'ltv60',
    dataIndex: 'ltv60',
    width: 90,
  },
  {
    title: 'ltv90',
    dataIndex: 'ltv90',
    width: 90,
  }
]

//app下拉选择
const StoreIdSelect = ({ makeRequest }) => {
  const [apps, setApps] = useState([]);

  useEffect(() => {
    makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/ac/store?v=store_id`, null, setApps, null)
  }, [makeRequest]);

  return <Form.Item label="应用" name="storeId" rules={[{ required: true, message: '请选择应用!' }]}>
    <Select
      showSearch
      filterOption={(input, option) =>
        (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
      }
    >
      {
        apps.map(d => <Select.Option key={d.v}>{d.k}</Select.Option>)
      }
    </Select>
  </Form.Item>
}

const SearchForm = ({ csv, setDta, setLd, b, makeRequest }) => {

  const [form] = Form.useForm();

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current > moment();
  }

  const onFinish = (values) => {
    setLd(true)
    const okfn = (data) => {
      if (data.length > 1) {
        //所有列名
        const header = Object.keys(data[0]);
        //date列必存在最优先显示
        let cols = [{
          title: "日期",
          dataIndex: "date1",
          fixed: true,
          width: 210
        }];
        //分组列次优先显示
        header.filter(h => h in group_fields).forEach(h => {
          cols.push(group_fields[h]);
        })
        //+数据列
        if (b) {
          //详细信息
          cols = cols.concat(data_fields)
        } else {
          //简要信息
          cols = cols.concat(data_fields_main)
        }
        //总结行
        let sumRow = [];
        let sumData = data.pop();
        for (var i = 0; i < cols.length; i++) {
          let dataIndex = cols[i].dataIndex;
          sumRow.push(<Table.Summary.Cell>{sumData[dataIndex]}</Table.Summary.Cell>);
          //ctr点击率列在点击后面
          if (dataIndex === 'clicks') {
            let ctr = '0.00';
            if (sumData['impressions'] > 0) {
              ctr = (sumData[dataIndex] / sumData['impressions']).toFixed(2);
            }
            sumRow.push(<Table.Summary.Cell>{ctr}</Table.Summary.Cell>);
            i++
          }
          //cvr转化率列在安装后面
          if (dataIndex === 'installs') {
            let cvr = '0.00';
            if (sumData['clicks'] > 0) {
              cvr = (sumData[dataIndex] / sumData['clicks']).toFixed(2);
            }
            sumRow.push(<Table.Summary.Cell>{cvr}</Table.Summary.Cell>);
            i++
          }
          //cpc、cpi在spend后面
          if (dataIndex === 'spend') {
            let cpc = '0.00';
            if (sumData['impressions'] > 0) {
              cpc = (sumData[dataIndex] / sumData['impressions']).toFixed(2);
            }
            sumRow.push(<Table.Summary.Cell>{cpc}</Table.Summary.Cell>);
            i++
            let cpi = '0.00';
            if (sumData['installs'] > 0) {
              cpi = (sumData[dataIndex] / sumData['installs']).toFixed(2);
            }
            sumRow.push(<Table.Summary.Cell>{cpi}</Table.Summary.Cell>);
            i++
          }
          //ecpm、利润在收入后面
          if (dataIndex === 'revenue_usd') {
            let ecpm = '0.00';
            if (sumData['imp_all'] > 0) {
              ecpm = (sumData[dataIndex] /sumData['imp_all'] * 1000).toFixed(2);
            }
            sumRow.push(<Table.Summary.Cell>{ecpm}</Table.Summary.Cell>);
            i++
            let profit = (sumData[dataIndex] - sumData['spend']).toFixed(2);
            sumRow.push(<Table.Summary.Cell>{profit}</Table.Summary.Cell>);
            i++
          }
        }
        setDta(pre => ({
          ...pre, cols: cols, ds: data, sum:
            <Table.Summary fixed>
              <Table.Summary.Row key={Math.random().toString(36).slice(-6)}>
                {sumRow}
              </Table.Summary.Row>
            </Table.Summary>
        }));
      } else {
        setDta(pre => ({ ...pre, cols: [], ds: [], sum: <></> }));
      }
    }
    //先用post吧
    makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/ac/performance`, values, okfn, setLd)
  };

  const onChange = (e) => {
    setDta(pre => ({ ...pre, b: e.target.checked }));
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 21 }}
      name="advanced_search"
      onFinish={onFinish}
    >
      <Row>
        <Col span={6} key='range_'>
          <Form.Item label="区间" name="range" rules={[{ required: true, message: '请选择日期!' }]}>
            <RangePicker disabledDate={disabledDate} style={{ width: '100%' }}
              ranges={{
                Today: [moment(), moment()],
                'Yesterday': [moment().add(-1, 'd'), moment().add(-1, 'd')],
                'Last 7 days': [moment().add(-7, 'd'), moment().add(-1, 'd')],
                'Last 7 days and today': [moment().add(-7, 'd'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last 30 days': [moment().add(-30, 'd'), moment()],
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6} key='apps'>
          <StoreIdSelect makeRequest={makeRequest} />
        </Col>
        <Col span={6} key='network'>
          <Form.Item label="渠道" name="network">
            <Select>
              <Select.Option value="appleads">apple</Select.Option>
              <Select.Option value="applovin">applovin</Select.Option>
              <Select.Option value="facebookads">facebook</Select.Option>
              <Select.Option value="googleads">google</Select.Option>
              <Select.Option value="ironsrc">ironsrc</Select.Option>
              <Select.Option value="mintegral">mintegral</Select.Option>
              <Select.Option value="unity">unity</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} key='country'>
          <Form.Item name="countries" label="国家">
            <Select mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6} key='group'>
          <Form.Item name="group" label="字段" >
            <Select mode="multiple" allowClear placeholder="分组字段">
              <Option value='store_id'>app</Option>
              <Option value='date1'>date</Option>
              <Option value='network'>nerwork</Option>
              <Option value='network_campaign'>campaign</Option>
              {/* <Option value='network_adset'>adset</Option>
              <Option value='network_ad'>ad</Option> */}
              <Option value='country'>country</Option>
              <Option value='os'>os</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Row>
            <Col offset={3} span={21}>
              <Form.Item >
                <Checkbox onChange={onChange}>多天数据</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={6} key="filters">
          <Form.Item label="过率">
            <Form.List name="filters">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(field => (
                    <Filter remove={remove} field={field} makeRequest={makeRequest} />
                  ))}

                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      过率条件
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ textAlign: 'right' }}>
        <Col span={24}>
          <Space>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button onClick={() => { form.resetFields(); }}>
              Clear
            </Button>
            <Button type="primary" onClick={csv} >
              Export
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

function Filter({ remove, field, makeRequest }) {
  const [store, setStore] = useState([]);
  const qrystore = (value) => {
    makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/ac/store?v=${value}`, null, setStore, null)
  }

  return (
    <Row key={field.key}>
      <Col span={7}>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) =>
            prevValues.v !== curValues.v || prevValues.k !== curValues.k
          }
        >
          {() => (
            <Form.Item
              {...field}
              name={[field.name, 'k']}
              rules={[{ required: true, message: '请选择!' }]}
            >
              <Select onChange={qrystore}>
                <Option key='network_campaign' value='network_campaign'>campaign</Option>
                {/* <Option key='network_adset' value='network_adset'>adset</Option>
              <Option key='network_ad' value='network_ad'>ad</Option> */}
                <Option key='os' value='os'>os</Option>
              </Select>
            </Form.Item>
          )}
        </Form.Item>
      </Col>
      <Col span={14}>
        <Form.Item
          {...field}
          name={[field.name, 'v']}
          rules={[{ required: true, message: '缺少值' }]}
        >
          <Select
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={true}
            notFoundContent={null}
          >
            {store.map(d => <Option key={d.v}>{d.v}</Option>)}
          </Select>
        </Form.Item>
      </Col>
      <Col offset={1} span={2}>
        <MinusCircleOutlined onClick={() => remove(field.name)} />
      </Col>

    </Row>
  );
}

export default function Acquire({ delToken }) {

  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Performance`;
  });

  const [ld, setLd] = useState(false)
  const [dta, setDta] = useState({ cols: [], ds: [], b: false })
  const { makeRequest } = useSharedValues();

  const csv = () => {
    const header = Object.keys(dta.ds[0]);
    let csv = dta.ds.map(row => header.map(k => row[k]).join(','));
    csv.unshift(header.join(','));
    csv = csv.join('\r\n');
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI("\uFEFF" + csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'Performance_' + moment().format('YYYY-MM-DD-HH-mm-ss') + '.csv';
    hiddenElement.click();
  };

  return (
    <>
      <SearchForm csv={dta.ds.length > 0 ? csv : false}
        setDta={setDta}
        setLd={setLd}
        b={dta.b}
        makeRequest={makeRequest} />
      <Table
        locale={{ emptyText: '暂无数据' }}
        columns={dta.cols}
        dataSource={dta.ds}
        rowKey={(r) => Math.random().toString(36).slice(-6)}
        scroll={{ y: '100vh' }}
        loading={ld}
        pagination={false}
        bordered={true}
        summary={() => dta.sum}
      />
    </>
  );
}


