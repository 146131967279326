import { Result, Button } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { useSharedValues } from './SharedValueProvider';

// export function UseToken() {
//     const [token, setToken] = useState(sessionStorage.getItem('token'));
//     const saveToken = userToken => {
//         sessionStorage.setItem('token', userToken);
//         setToken(userToken);
//     };
//     const delToken = () => {
//         sessionStorage.removeItem('token');
//         setToken(null);
//     };
//     return {
//         delToken: delToken,
//         setToken: saveToken,
//         token
//     }
// }

// export function UesRequest() {
//     const { token, setToken } = useSharedValues();

//     const makeRequest = async (method, url, data, okfn, ldfn) => {
//         return await fetch(url, {
//             method: method,
//             mode: 'cors',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Auth': token
//             },
//             body: data ? JSON.stringify(data) : null
//         })
//             .then(response => response.json().then(dta => ({ s: response.status, d: dta })))
//             .then(obj => {
//                 ldfn?.(false);
//                 if (obj.s === 200) {
//                     okfn?.(obj.d.data);
//                 } else if (obj.s === 401) {
//                     Modal.error({
//                         content: obj.d.err,
//                         onOk: setToken(null)
//                     });
//                 } else {
//                     Modal.error({
//                         content: obj.d.err,
//                     });
//                 }
//             })
//             .catch((error) => {
//                 ldfn?.(false);
//                 Modal.error({
//                     content: error.message,
//                 });
//             });
//     }
//     return { makeRequest }
// }

// export function UseApps({ token, makeRequest }) {
//     const [apps, setApps] = useState(sessionStorage.getItem('apps'));
//     const saveApps = (appsObj) => {
//         const str = JSON.stringify(appsObj);
//         sessionStorage.setItem('apps', str);
//         setApps(str);
//     };
//     useEffect(() => {
//         if (!apps) {
//             makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/apps`, null, saveApps, null)
//         }
//     }, [token]);

//     return {
//         setApps: saveApps,
//         apps
//     }
// }

export function Use404() {
    const location = useLocation();
    const { apps } = useSharedValues();

    let allowedValues = apps.map(item => item.name);
    const pattern = new RegExp(`^/modp/(${allowedValues.join('|')})(/.*)?$`);
    return !pattern.test(location.pathname) ? <Result
        status="404"
        title="404"
        subTitle="抱歉，这个页面暂时不存在。"
        extra={<Button type="primary"><Link to={`/modp`} >Back Home</Link></Button>}
    /> : null
}