
import * as React from 'react';

import { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Table, DatePicker, Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { useSharedValues } from './SharedValueProvider';

const { RangePicker } = DatePicker;
const { Option } = Select;
const rangeConfig = {
  rules: [
    {
      required: true,
      message: '请选择日期区间！',
    },
  ],
};

const gp1 = {
  date0: {
    title: "date",
    dataIndex: "date0",
    ellipsis: true,
    width: 200
  },
  skad_ad_network_id: {
    title: "network",
    dataIndex: "skad_ad_network_id",
    ellipsis: true,
    width: 169
  },
}
const gp2 = {
  ad_network_campaign_name: {
    title: "campaign",
    dataIndex: "ad_network_campaign_name",
    ellipsis: true,
    width: 240
  },
  country_code: {
    title: "country",
    dataIndex: "country_code",
    width: 40
  }
}

const SearchForm = ({ csv, setDta, setLd, makeRequest }) => {

  const okfn2 = (data) => {
    if (data.length > 0) {
      //所有字段
      const header = Object.keys(data[0]);
      //优先显示分组字段
      let cols = []; let sumRow = [];
      header.filter(h => h in gp1).forEach(h => {
        console.log(gp1[h])
        cols.push(gp1[h]);
        sumRow.push(<Table.Summary.Cell>{h === "date0" ? "Total" : ""}</Table.Summary.Cell>);
      })
      header.filter(h => h in gp2).forEach(h => {
        cols.push(gp2[h]);
        sumRow.push(<Table.Summary.Cell />);
      })

      let conversionValues = []; let colSum = [];
      header.filter(h => !(h in gp2) && !(h in gp1)).forEach(h => {
        cols.push({
          title: h,
          dataIndex: h,
          width: 56,
          ellipsis: true,
        });
        conversionValues.push(h); colSum.push(0);
      })
      data.forEach(r => {
        for (var i = 0; i < conversionValues.length; i++) {
          colSum[i] = Number(colSum[i]) + Number(r[conversionValues[i]]);
        }
      });
      for (var i = 0; i < conversionValues.length; i++) {
        sumRow.push(<Table.Summary.Cell>{colSum[i]}</Table.Summary.Cell>);
      }
      setDta(pre => ({
        ...pre, cols: cols, ds: data, sum:
          <Table.Summary fixed>
            <Table.Summary.Row key={Math.random().toString(36).slice(-6)}>
              {sumRow}
            </Table.Summary.Row>
          </Table.Summary>
      }));
    } else {
      setDta(pre => ({ ...pre, cols: [], ds: data, sum: <></> }));
    }
  }

  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLd(true);
    makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/skad`, values, okfn2, setLd)
  };

  const disabledDate = (current) => {
    return current > dayjs();
  }

  return (
    <Form
      form={form}
      name="advanced_search"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={18} >
          <Row gutter={24}>
            <Col span={4}>
              <Form.Item label="应用" name="app" rules={[{
                required: true,
                message: '请选择应用！',
              }]}>
                <Select
                  allowClear
                >
                  <Option key='id6448081136'>id6448081136</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="区间" name="range" {...rangeConfig} >
                <RangePicker disabledDate={disabledDate} style={{ width: '100%' }}
                  presets={[
                    { label: 'Today', value: [dayjs(), dayjs()] },
                    { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
                    { label: 'Last 7 days', value: [dayjs().add(-7, 'd'), dayjs().add(-1, 'd')] },
                    { label: 'Last 7 days and today', value: [dayjs().add(-7, 'd'), dayjs()] },
                    { label: 'This Month', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                    { label: 'Last 30 days', value: [dayjs().add(-30, 'd'), dayjs()] },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="group" label="分组">
                <Select
                  mode="multiple"
                  allowClear
                >
                  <Option key='country_code'>country</Option>
                  <Option key='date'>day</Option>
                  <Option key='skad_ad_network_id'>network</Option>
                  <Option key='ad_network_campaign_name'>campaign</Option>
                </Select>
              </Form.Item>
            </Col>

          </Row>
        </Col>
        <Col span={6}>
          <Row gutter={24}>
            <Col>
              <Button type="primary" htmlType="submit">Search</Button>
            </Col>
            <Col>
              {csv ? <Button onClick={csv}
              >
                <DownloadOutlined />
              </Button>
                : <></>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default function Skad() {

  useEffect(() => {
    document.title = `skad`;
  });
  const [dta, setDta] = useState({ cols: [], ds: [] })
  const [ld, setLd] = useState(false)
  const { makeRequest } = useSharedValues()


  const csv = () => {

    const ffs2 = {
      network: "skad_ad_network_id",
      campaign: "ad_network_campaign_name",
      country: "country_code",
    }

    const header = [];
    dta.cols.map(col => header.push(col.title))
    const csv = [
      header.join(','), // header row first
      ...dta.ds.map(row => header.map(fieldName => fieldName in ffs2 ? row[ffs2[fieldName]] : row[fieldName]).join(','))
    ].join('\n')

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'skad_cv_' + dayjs().format('YYYYMMDD_HHmmss') + '.csv';
    hiddenElement.click();
  };

  return (
    <>
      <SearchForm csv={dta.ds.length > 0 ? csv : false}
        setDta={setDta}
        setLd={setLd}
        makeRequest={makeRequest}
      />
      <Table
        locale={{ emptyText: '暂无数据' }}
        columns={dta.cols}
        dataSource={dta.ds}
        rowKey={() => Math.random().toString(36).slice(-6)}
        loading={ld}
        scroll={{ y: '60vh' }}
        pagination={false}
        bordered={true}
        size="small"
        summary={() => dta.sum}
      />
    </>
  );
}
