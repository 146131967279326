import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Input, Table, Modal, InputNumber } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { Use404 } from "./Use"
import { useSharedValues } from './SharedValueProvider';

const SearchForm = ({ queryList, clickAdd }) => {
    return <Form
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            onFinish={queryList}
        >
            <Row gutter={18}>
                <Col xs={12} sm={4} md={6} lg={8} xl={16}>
                    <Row gutter={18}>
                        <Col xs={16} sm={4} md={6} lg={8} xl={10}>
                            <Form.Item name="dn">
                                <Input placeholder="名称模糊查询" allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={4} sm={4} md={6} lg={8} xl={10}>
                            <Button type="primary" htmlType="submit">搜索</Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={4} md={6} lg={8} xl={8} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={clickAdd} >
                        添加活动
                    </Button>
                </Col>
            </Row>
        </Form>
};

const { confirm } = Modal;

export default function EventDef() {

    let { app } = useParams();
    const p404 = Use404();
    const [ds, setDs] = useState([])
    const [ld, setLd] = useState(false)
    const [open, setOpen] = useState(false);
    const { makeRequest } = useSharedValues()
    const [deviceForm] = Form.useForm();
    const [id, setId] = useState(0);

    const queryList = useCallback((values) => {
        let dn = values['dn'] ? values['dn'] : '';
        setLd(true);
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/events?dn=${dn}`, null, setDs, setLd)
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `EventDef`;
            queryList({})
        }
    }, [p404, queryList]);

    if (p404) {
        return p404
    }

    const delOk = (id) => {
        setDs(ds => ds.filter(item => item.id !== id));
    }
    const showPromiseConfirm = (id) => {
        confirm({
            title: '确定删除这个设备?',
            icon: <ExclamationCircleOutlined />,
            content: '从设备列表删除（玩家数据不会删除）',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                setLd(true)
                makeRequest('DELETE', `${process.env.REACT_APP_API_URL}/modp/r/${app}/events/${id}`, null, delOk, setLd)
            },
            onCancel() { },
        });
    }

    const clickAdd = () => {
        deviceForm.resetFields();
        setOpen(true);
        setId(0);
    }
    const clickModify = (r) => {
        deviceForm.setFieldsValue({
            event_id: r.event_id,
            event_name: r.event_name,
            // beta: r.beta,
        })
        setOpen(true);
        setId(r.id)
    }

    const okfn = () => {
        setOpen(false);
        queryList({});
    }
    const createDev = (values) => {
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/events`, values, okfn, setLd)
    };

    const updateDev = (values) => {
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/events/${id}`, values, okfn, setLd)
    }

    const betaOk = (changed) => {
        setDs(ds => ds.map(item => item.id === changed.id ? changed : item))
    }
    const beta = (r) => {
        setLd(true)
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/events/${r.id}`, r, betaOk, setLd)
    };

    const columns = [
        {
            title: '活动名称',
            dataIndex: 'event_name',
            width: 136,
        },
        {
            title: '值',
            dataIndex: 'event_id',
            width: 136,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 128,
        },
        // {
        //     title: '操作',
        //     width: 224,
        //     render: (_, r) => {
        //         return <>
        //             <Row gutter={8} justify="start">
        //                 <Col>
        //                     <Button type="dashed" onClick={() => clickModify(r)}>
        //                         修改
        //                     </Button>
        //                 </Col>
        //                 <Col>
        //                     <Button type="dashed" danger
        //                         onClick={() => showPromiseConfirm(r.id)}
        //                     >
        //                         删除
        //                     </Button>
        //                 </Col>
        //             </Row>
        //         </>
        //     },
        // },
    ]

    return (
        <>
            <SearchForm app={app} queryList={queryList} clickAdd={clickAdd} />

            <Modal
                // title="设备信息"
                open={open}
                okText="保存"
                cancelText="取消"
                onCancel={() => {
                    setOpen(false);
                }}
                onOk={() => {
                    deviceForm.submit()
                }}
            >
                <Form
                    form={deviceForm}
                    layout="vertical"
                    onFinish={(values) => {
                        if (id > 0) {
                            updateDev(values)
                        } else {
                            createDev(values)
                        }
                    }}
                >
                    <Form.Item
                        label="活动名称"
                        name="event_name"
                        rules={[
                            {
                                required: true,
                                message: '请输入活动名称！',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="值"
                        name="event_id"
                        rules={[
                            {
                                required: true,
                                message: '请输入活动id！',
                            },
                        ]}
                    >
                         <InputNumber min={0} step={1} precision={0} />
                    </Form.Item>
                </Form>
            </Modal>
            
            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '56vh' }}
                pagination={false}
                bordered={true}
            />
        </>

    );
}
