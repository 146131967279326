import * as React from 'react';

import { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Tag, Switch, Table, Radio } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { UesRequest } from './Use';
import { useSharedValues } from './SharedValueProvider';

const SearchForm = ({ setLd, setDs, makeRequest }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.submit();
    }, [form])

    const onFinish = (values) => {
        setLd(true);
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/apps`, values, setDs, setLd)
    };

    return (
        <Form
            form={form}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 20 }}
            name="advanced_search"
            onFinish={onFinish}
        >
            <Row gutter={24}>
                <Col span={8} key="app">
                    <Form.Item label="应用" name="App">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Button type="primary" htmlType="submit">
                        Search
                    </Button>
                    <Button
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        Clear
                    </Button>
                </Col>
            </Row>
            <Row>
            </Row>
        </Form>
    );
};

export default function Setts() {

    
    const [ds, setDs] = useState([])
    const [ld, setLd] = useState(false)
    const { makeRequest } = useSharedValues()
    
    useEffect(() => {
        document.title = `Mapps`;
    },[makeRequest]);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
        }, {
            title: '应用',
            dataIndex: 'name',
        }, {
            title: '商店地址',
            dataIndex: 'storeId',
            render: (text, record) => {
                if (text) {
                    if (record.name.substr(0, 1) === "i") { //ios
                        return <a href={`https://apps.apple.com/app/id${text}`} target="_blank" rel="noreferrer">{text}</a>
                    } else { //android
                        return <a href={`https://play.google.com/store/apps/details?id=${text}`} target="_blank" rel="noreferrer">{text}</a>
                    }
                }
            }
        }, {
            title: '国家',
            dataIndex: 'countries',
            render: (tags, record) => {
                return (
                    <span>
                        {tags.map(tag => {
                            return (
                                <Tag color='blue' key={tag} >
                                    {tag.toUpperCase()}
                                </Tag>
                            );
                        })}
                    </span>
                )
            },
        }, {
            title: '忽略N1',
            dataIndex: 'onlyBycc',
            render: (text, record) => {
                return <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={text}
                />;
            },
        },
        {
            title: '强制',
            dataIndex: 'orgForce',
            render: (text, record) => {
                return <Radio.Group value={text} size="small" buttonStyle="solid">
                    <Radio.Button value={-1}>NO</Radio.Button>
                    <Radio.Button value={0}>NOT_ORG</Radio.Button>
                    <Radio.Button value={1}>ORG</Radio.Button>
                </Radio.Group>;
            },
        },
        {
            title: '版本',
            dataIndex: 'versionAudit',
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (text, record) => {
                if (text === 4) {
                     return <span>AUDIT</span>
                }
                return   <span>OK</span>
            },
        },
        {
            title: '操作',
            render: (text, record) => {
                let lk = "/modp/mapps/" + record.id
                return (
                    <span>
                        <Link to={lk}>
                            <Button type="link" >Edit</Button>
                        </Link>
                    </span>
                )
            },
        },
    ]

    return <>
        <SearchForm setDs={setDs} setLd={setLd} makeRequest={makeRequest} />
        <Table columns={columns} dataSource={ds} scroll={{ y: '59vh' }} loading={ld}
            pagination={false} bordered={true} rowKey={r => r.id}
        />
    </>
}
