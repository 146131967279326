// SharedValueContext.js
import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { Modal, message } from 'antd';
import Login from './Login';
import Main from './Main';

const SharedValueContext = createContext();

export const SharedValueProvider = () => {

  const [token, setToken] = useState(sessionStorage.getItem('token'));
  const [apps, setApps] = useState(JSON.parse(sessionStorage.getItem('apps')));
  const [messageApi, contextHolder] = message.useMessage();

  const clear = () => {
    setApps(null);
    sessionStorage.removeItem('apps');
    setToken(null);
    sessionStorage.removeItem('token');
    messageApi.open({
      type: 'error',
      content: '登录信息有误或失效',
    });
  }

  const makeRequest = useCallback(async (method, url, data, okfn, ldfn) => {
    return await fetch(url, {
      method: method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Auth': token
      },
      body: data ? JSON.stringify(data) : null
    })
      .then(response => response.json().then(dta => ({ s: response.status, d: dta })))
      .then(obj => {
        ldfn?.(false);
        if (obj.s === 200) {
          okfn?.(obj.d.data);
        } else if (obj.s === 401) {
          clear();
        } else {
          Modal.error({
            content: obj.d.err,
          });
        }
      })
      .catch((error) => {
        ldfn?.(false);
        Modal.error({
          content: error.message,
        });
      });
  }, [token])

  const saveApps = useCallback((apps) => {
    sessionStorage.setItem('apps', JSON.stringify(apps));
    setApps(apps);
  }, [setApps])

  const saveToken = useCallback((token) => {
    sessionStorage.setItem('token', token);
    setToken(token);
  }, [setToken])

  useEffect(() => {
    if (token) {
      makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/apps`, null, saveApps, null);
    }
  }, [token, makeRequest, saveApps]);

  return <>
    {contextHolder}
    <SharedValueContext.Provider value={{ token, makeRequest, apps }}>
      {!token || !apps ?
        <Login setToken={saveToken} makeRequest={makeRequest} /> : <Main />
      }
    </SharedValueContext.Provider>
  </>
};

export const useSharedValues = () => {
  return useContext(SharedValueContext);
};
