import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ConfigProvider, theme } from 'antd';
import { SharedValueProvider } from './SharedValueProvider';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        algorithm: theme.compactAlgorithm,
      }}
    >
      <SharedValueProvider />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

