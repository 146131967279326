import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Input, Table, Modal, Tag, Tooltip } from 'antd';
import { useSharedValues } from './SharedValueProvider';
import {
    CheckCircleOutlined,
    SyncOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';

const { TextArea } = Input;

const SearchForm = ({ queryList, clickAdd }) => {
    const [formSearch] = Form.useForm();
    return <Form
        form={formSearch}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        onFinish={queryList}
    >

        <Row gutter={18}>
            <Col xs={12} sm={4} md={6} lg={8} xl={16}>
                <Row gutter={18}>
                    <Col xs={16} sm={4} md={6} lg={8} xl={10}>
                        <Form.Item name="dn">
                            <Input placeholder="名称模糊查询" allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={4} sm={4} md={6} lg={8} xl={10}>
                        <Button type="primary" htmlType="submit">搜索</Button>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} sm={4} md={6} lg={8} xl={8} style={{ textAlign: 'right' }}>
                <p>状态更新有延迟，几秒后才会检查操作是否成功更新</p>
            </Col>
        </Row>
    </Form>
};

export default function Oplog() {

    const [ds, setDs] = useState([])
    const [ld, setLd] = useState(false)
    const [open, setOpen] = useState(false);
    const { makeRequest } = useSharedValues()
    const [deviceForm] = Form.useForm();

    const queryList = useCallback((values) => {
        let dn = values['dn'] ? values['dn'] : '';
        setLd(true);
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/oplog?dn=${dn}`, null, setDs, setLd)
    }, [makeRequest])

    useEffect(() => {
        document.title = `Oplog`;
        queryList({})
    }, [queryList]);

    const clickAdd = () => {
        deviceForm.resetFields();
        setOpen(true);
    }

    const okfn = () => {
        setOpen(false);
        queryList({});
    }
    const createDev = (values) => {
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/certs`, values, okfn, setLd)
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 48,
        },
        {
            title: '应用',
            dataIndex: 'app',
            width: 124,
        },
        {
            title: '操作',
            // dataIndex: 'cmd',
            width: 124,
            render: (_, r) => {
                switch (r.cmd) {
                    case 0:
                        return '修改设备数据'
                    case 3:
                        return '更新txt'
                    case 4:
                        return '更新资源'
                    case 5:
                        return '更新ab类型'
                    case 6:
                        return '更新活动计划'
                    case 7:
                        return '更新工会活动'
                    case 8:
                        return '更新资源（新）'
                    default:
                        return '未知类型'
                }
            }
        },
        {
            title: '操作对象',
            dataIndex: 'object_id',
            width: 136,
        },
        {
            title: '状态',
            width: 128,
            render: (_, r) => {
                if (r.pending) {
                    return <Tag icon={<SyncOutlined spin />} color="processing">处理中</Tag>
                }
                return <Tag icon={<CheckCircleOutlined />} color="success">处理成功</Tag>
            }
        },
        {
            title: '操作时间',
            dataIndex: 'create_time',
            width: 128,
        },
    ]

    return (
        <>
            <SearchForm queryList={queryList} clickAdd={clickAdd} />

            <Modal
                // title="设备信息"
                open={open}
                okText="保存"
                cancelText="取消"
                onCancel={() => {
                    setOpen(false);
                }}
                onOk={() => {
                    deviceForm.submit()
                }}
            >
                <Form
                    form={deviceForm}
                    layout="vertical"
                    onFinish={(values) => {
                        createDev(values)
                    }}
                >
                    <Form.Item
                        label="域名"
                        name="domain"
                        rules={[
                            {
                                required: true,
                                message: '请输入域名！',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="用途"
                        name="use"
                    >
                        <TextArea rows={5} />
                    </Form.Item>
                </Form>
            </Modal>

            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '56vh' }}
                pagination={false}
                bordered={true}
            />
        </>

    );
}
