import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Table, Modal, Popconfirm, Input, Tag, DatePicker } from 'antd';
import { useParams } from "react-router-dom";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { Use404 } from './Use';
import { useSharedValues } from './SharedValueProvider';
import dayjs from 'dayjs';
import { render } from '@testing-library/react';

// response.blob().then(blob => {
//     var hiddenElement = document.createElement('a');
//     hiddenElement.href = URL.createObjectURL(blob);
//     hiddenElement.target = '_blank';
//     hiddenElement.download = response.headers.get("Content-Disposition").split("filename=")[1];
//     hiddenElement.click();
// })

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const SearchForm = ({ queryList, clickAdd }) => {
    const [formSearch] = Form.useForm();

    return <Form
            form={formSearch}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            onFinish={queryList}
        >
            <Row>
                <Col xs={24} xl={16} >
                    {/* <div style={{ textAlign: 'left' }}>
                        <p>时间是计算机本地时间（注意时区），可简单的添加、取消、启用计划</p>
                        <p>修改计划还是我来吧，正在进行的和5分钟内将要开始的计划暂无法（不重启）修改，计划修改后1-2分钟生效</p>
                    </div> */}
                </Col>
                <Col xs={18} xl={6} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => {
                        formSearch.submit()
                    }}>
                        刷新
                    </Button>
                </Col>
                <Col xs={6} xl={2} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={clickAdd}>
                        添加计划
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col >
                    <br></br>
                </Col>
            </Row>
        </Form>
};

export default function ConfigTreasure() {

    let { app } = useParams();
    const [ds, setDs] = useState([]);
    const [ld, setLd] = useState(false);
    const [open, setOpen] = useState(false);

    const { makeRequest } = useSharedValues()
    const p404 = Use404();

    const [formAdd] = Form.useForm();

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };

    const updateRow = (id, status) => {
        const updatedDataSource = [...ds]; // 创建一个新的数据源副本  
        const index = updatedDataSource.findIndex(item => item.id === id);
        if (index !== -1) {
            updatedDataSource[index] = { ...updatedDataSource[index], release_status: status }; // 更新找到的行数据  
        }
        setDs(updatedDataSource); // 设置更新后的数据源到状态变量  
    };

    const queryList = useCallback(() => {
        setLd(true);
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/treasure`, null, setDs, setLd)
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `Treasure`;
            queryList({});
        }
    }, [p404, queryList]);

    if (p404) {
        return p404;
    }

    const clickAdd = () => {
        formAdd.setFieldsValue({
            stages: '[100, 200, 300]',
            rewards: '[8401, 8402, 8403]',
            range_time: null,
        })
        setOpen(true);
    }

    const okfn = () => {
        setOpen(false);
        queryList({})
    }

    const createVer = (values) => {
        setLd(true);
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/treasure`, values, okfn, setLd);
    };

    const cancelPlan = (id) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/treasure/cancel/${id}`, null, okfn, setLd)
    }

    const abortPlan = (id) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/treasure/abort/${id}`, null, okfn, setLd)
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 72,
        },
        {
            title: '阶段',
            dataIndex: 'stages',
            width: 144,
        },
        {
            title: '奖励',
            dataIndex: 'rewards',
            width: 152,
        },
        {
            title: '开始时间',
            dataIndex: 'start_time',
            width: 128,
        },
        {
            title: '结束时间',
            dataIndex: 'end_time',
            width: 128,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 128,
            render: (_, r) => {
                if (!(r.status)) {
                    r.status = 0
                }
                switch (r.status) {
                    case 0:
                        return <Tag icon={<ClockCircleOutlined />} color="default">
                            计划中
                        </Tag>
                    case 1:
                        return <Tag icon={<CheckCircleOutlined />} color="success">
                            {r.pending ? '开启中' : '进行中'}
                        </Tag>
                    case 2:
                        return <Tag icon={<MinusCircleOutlined />} color="default">
                            已结束
                        </Tag>
                    case 3:
                        return <Tag icon={<MinusCircleOutlined />} color="warning">
                            {r.pending ? '取消中' : '已取消'}
                        </Tag>
                    case 4:
                        return <Tag icon={<CloseCircleOutlined />} color="error">
                            {r.pending ? '终止中' : '已终止'}
                        </Tag>
                    default:
                        return <>未知状态</>
                }
            }
        },
        {
            title: '操作',
            width: 176,
            render: (_, r) => {
                if (!(r.status)) {
                    r.status = 0
                }
                switch (r.status) {
                    case 0: //未开始
                        return <Row gutter={16} justify="start">
                            <Col>
                                <Popconfirm title="确定要取消此计划吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => cancelPlan(r.id)}
                                >
                                    <Button type="dashed">
                                        取消
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    case 1: //已取消
                        return <Row gutter={16} justify="start">
                            <Col>
                                <Popconfirm title="确定要终止此版本吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => abortPlan(r.id)}
                                >
                                    <Button type="dashed" danger>
                                        终止
                                    </Button>
                                </Popconfirm>
                            </Col> 
                            {/* <Col>
                                <Popconfirm title="确定启用此计划吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => togglePlan(r.id)}
                                >
                                    <Button type="dashed">
                                        启用
                                    </Button>
                                </Popconfirm>
                            </Col> */}
                        </Row>
                    default:
                        return <></>
                }
            },
        },
    ]

    return (
        <>
            <SearchForm queryList={queryList} clickAdd={clickAdd} />

            <Modal
                open={open}
                okText="保存"
                cancelText="取消"
                onCancel={() => {
                    setOpen(false);
                }}
                onOk={() => formAdd.submit()}
            >
                <Form
                    form={formAdd}
                    layout="vertical"
                    onFinish={createVer}
                >
                    <Form.Item
                        name="stages"
                        label="阶段"
                        rules={[
                            {
                                required: true,
                                message: '请输入阶段',
                            },
                        ]}
                    >
                        <TextArea />
                    </Form.Item>

                    <Form.Item
                        name="rewards"
                        label="奖励"
                        rules={[
                            {
                                required: true,
                                message: '请输入奖励内容',
                            },
                        ]}>
                        <TextArea />
                    </Form.Item>

                    <Form.Item
                        name="range_time"
                        label="活动时间"
                        rules={[
                            {
                                required: true,
                                message: '请选择计划时间',
                            },
                        ]}>
                        <RangePicker
                            disabledDate={disabledDate}
                            style={{ width: '100%' }}
                            showTime={{
                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                            }}
                            format="YYYY-MM-DD HH:mm:ss"
                        />
                    </Form.Item>

                </Form>
            </Modal>

            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '68vh' }}
                pagination={false}
                bordered={true}
            />
        </>
    );
}
